import React from "react";
import Layout from "../../components/layout";
import ManuelEnMarleen from '../../components/portfolio/manuelEnMarleen.component';


export default function ManuelEnMarleenGalleryPage(props){


  return (
    <Layout>
    <ManuelEnMarleen/>
  </Layout>
  )
}



